table.keyValuesComponent td,tr,th{
    
    vertical-align: top;
    text-align: start;
    
}
.keyvalue-value-cell{

    
}


.keyvalue-value-cell.withFadeout{
    max-height: 2.15em;
    overflow: hidden;
    position: relative;
    

}

.keyvalue-value-cell .fadeout{
    /* background-image: linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 100%); */
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 25%, rgba(255,255,255,0) 80%); 
    margin-top: 1.0em;
    height: 1.15em;
    top:0px;
  z-index: 1;
  cursor: zoom-in
  
}

.keyvalue-value-cell.withFadeout:active{
    max-height: unset !important;
}
.keyvalue-value-cell.withFadeout:active .fadeout{
    display: none;
}

