
.funcEdit{
    border-radius: 5px;
}

.editIcon{
    display: none;
}
.funcEdit .editIcon{
    display: inline-block;
    color: lightgray;
}
.funcEdit:hover{
    background-color: #e6e6e6;
    border: 1px solid gray
}

.funcEdit:hover .editIcon{
    color: black;
}