.homePage{
    background: linear-gradient(#1a1a1a, #3c413e);
}

.page-body{
   
}
.dashboard-img{
     /* border-bottom: 0.5px solid rgb(38, 38, 38);   */
    
    flex: 1;
    flex-direction: column;
    z-index: 1;
    height: 45vw;
    max-height: 600px;
    width: 100%;
    overflow: hidden;
    display: block;
    
}

.dashboard-img .vid{
    width: 70%;
    max-width: 1000px;
    align-self: center;
    box-shadow: 10px 15px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    overflow: hidden;
    margin-top: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 1px solid rgba(91, 185, 141, 0.3);
    border-radius: 10px;
    margin-bottom: -5px;
}

/* .dashboard-img img{
    width: 70%;
    height: auto;
    max-width: 1000px;

} */


a.nav{
    color:white;
    font-weight: 900;
    font-size: 20px;
    text-decoration: none;
}
a.nav:hover{
    color: #6FFFB0;
    text-decoration: none;
}

.exampleIntegration{
    
    
    align-self: end;
    position: absolute;
    bottom: 0;
    right: 0;
    
}

.fadeout-img{
    
    
    height: 500px;
    width: auto;
    margin-right: 150px;
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
    align-self: start;

}
.rightSide{
    position: relative;
}

.get-api-key-btn-box{
    
    position: relative;
    z-index: 25;
    
    right:10px;
    display: flex;
    align-self: start;
    flex-direction: column;
}
.codeBlock {
    align-self: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
}
.rightSide {
    align-self: center;
    margin: 40px 10px;
}

.headline {
    text-align: start;
    max-width: 40vw;
    
}
.headline h1{
    font-size: 80px;
    line-height: 1.0;
    margin-bottom: 0px;
    background: linear-gradient(140deg, #6FFFB0 20%, #cb17d6 70%);
   
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.headline h2{
    font-size: 22px;
    line-height: 1.5 !important;
    color: #c0c0c0;
    font-weight: 900;
    line-height: 0.5;
}
.headline mark{
   background-color: #cc17d6a3;
    color: white;
}

@media only screen and (max-width: 767px) {
    .headline h1 {
    font-size: 50px;
    }
    .headline h2 {
        font-size:20px;
    }
}

  
   @media (max-width: 1200px) {
    .headline {
      width: 100%;
      max-width: 100% !important;
    }
    .headline h1{
        margin-bottom: 0px;
    }
    .page-body{
        align-content: center;
    }
    .rightSide{
        width: 100%;
    }
  } 
  .firstBlock{
    min-height: 70vh !important;
    
  }

