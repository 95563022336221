.chat-message{
    border: 1px solid gray;
    margin: 10px 5px;
    
    padding: 5px;
    background-color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 500;
    color:#1b1b1b;
}
.chat-message .content{
    white-space: pre-line;
    flex-grow: 1;
}

.chat-message.role-user{
    border-radius: 10px 0px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px; 
    /* box-shadow: rgba(255, 0, 187, 0.4) 0px 3px 3px; */
    
    
    
}
.chat-message.role-system{
    border-radius: 5px;
    border: 2px dashed rgba(128, 128, 128, 0.609);

}



.chat-message.role-assistant{
    border-radius: 0px 10px 10px 10px;
    /* box-shadow: rgba(101, 255, 132, 0.6) 0px 3px 3px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px; 
}
.chat-message span.generated mark{
    border: 1px dashed black;
    line-height: 120%;
    
    background-color: rgb(111,255,175);
}

.chat-message-role-info {    
    margin: 1px;

    padding: 2px;
    border-radius: 4px;
    background-color: rgb(239, 210, 210);
}
.chat-message-role-info.role-user{
    
    background-color: #fbb5ff7a;
    color: #5b0060;
}

.chat-message-role-info.role-function{
    
    background-color: #c5c5c57a;
    color: #000000;
    
}
.chat-message-role-info.role-system{
    background-color: rgba(101, 232, 255, 0.2);
    color: rgb(0, 75, 89)
}

.chat-message-role-info.role-assistant{
    background-color: rgba(101, 255, 132, 0.2);
    color: rgb(1, 89, 0)
}
.generated-msg {
    padding: 5px;
}
.generated-msg mark{
    white-space:"pre-wrap"  !important;
    font-size: 10pt;
    border: 0.2px rgb(114, 114, 114) solid;
    
    line-height: 1.2;
    
    
    background-color: rgba(111, 255, 176, 0.3);
    display: inline;
    
}
