.question-box{
    margin: 6px 0px 0px;
    border-radius: 10px 0px 10px 10px !important;
}

.answer-box{
    margin: 8px 0px 0px;
    border-radius: 0px 10px 10px 10px !important;
}



.sequence-info{
    background-color: white;
    border: 0.5px solid gray;
    margin: 5px;
    border-radius: 5px;
}
.sequence-info.open{
    max-height: unset;
}

.sequence-info.collapsed{
    max-height: 100px;
    overflow: hidden;
}

.chain-sequence{
    

}
.chain-sequence-inside{
    background-color: rgba(90,100,100,0.1);
    /* background-color: rgba(7, 8, 8, 0.6); */
    border: 2px dashed rgb(193, 193, 193);
}

.retrieved-docs{
    border: 1px solid rgb(172, 172, 172);
}

.parallel-prompt{
    
}


.action{
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
}

.llm-prompt{
    border: 1px solid rgb(172, 172, 172);
    border-radius: 5px;
}

.llm-prompt span.generated mark{
    background-color: rgba(111, 255, 176, 0.3);
    display: inline;
    white-space: pre-line;
}

.log{
    
}

.question{
    
}
.question-box{
    box-shadow: rgba(94, 94, 94, 0.55) 0px 5px 15px 0px;
    padding: 10px 10px;
    font-weight: 900;
}

.answer{
    
}
.answer-box{
    box-shadow: rgba(94, 94, 94, 0.55) 0px 5px 15px 0px;
    padding: 10px 10px;
    border: 1px solid gray;
    font-weight: 900;
}

