.loader{
    position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  z-index: 9999;  
  align-items: center;
  justify-content: center;
}