.demo-overlay {
    position: absolute;
    top: 0;
    
    width:calc(100vw - 80px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Set the background color to a dark semi-transparent color */
    backdrop-filter: blur(5px); /* Set the backdrop filter property to blur and adjust the blur radius as needed */
    /* Add any other styles you want for the full-screen overlay */
    display:flex;
    align-items: center;
    justify-content: center;
    
  }
  .infoBox{
    color: black;
    background-color: white;
    border: 1px solid black;
    padding: 20px;
    position: absolute;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }