.App {
  text-align: center;
  
  background-color: #e8f6ff;
background-image: linear-gradient(160deg, #f5f4f6 0%, #f5f4f6 100%);

}

a.no-style{
  text-decoration: none;
  color: inherit;
}

.pageHeader{
  background-color: white;
  position:sticky;
  top:0;
  padding-bottom: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 0px 0px 5px 0px;
  background-color: #50555e !important;
  color: white;
  
}
code {
  white-space: pre-wrap;
  
  
}

code.inline {
  white-space: pre-wrap;
  display: inline !important;
  color: #ea46f2;
  font-weight: 500;
  padding: 0px 2px;
  border: 1px solid #e946f235 !important;
  border-radius: 4px;
  
}

.second-level-sidebar{
  
  box-shadow: rgba(0, 0, 0, 0.2) 10px 0px 15px 0px !important;
}

.no-wrap{
  white-space: nowrap;
}

.fadeout{
  background-image: linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 100%);
  
  height: 40px;
  z-index: 1;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  
  position: absolute;
  top: 0px;
  left:1px;
  right:1px;
  
  
  
}
.fadeout-parent{
  position: relative;
  max-height: 100px;
  overflow: hidden;
}

body, #root {
	margin: 0;
	padding: 0;
	background-color: #fff;
	font-family: Arial, sans-serif;
  height: 100vh;
}



.highlightableText table, th, td{
  border: 1 px solid #383c34;
}
.highlightableText p{
  margin: 0px
}

code{
  overflow-x: auto;
  font-size: small;
  line-height: 120%;
  display: block;
  padding: 10px;
  text-align: start;
}

p{
  margin: 10px 0px;
}
strong{
  margin: 0px 3px;
}
a{
  margin: 0px 3px;
}

.SignUpButton{
  all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #7D4CDB;
    color: #fff;
    font-size: 18px;
    border-radius: 27px;
    border: 3px solid rgba(255, 255, 255,0.6);
    padding: 5px 26px;
    font-weight: bold;
    height: 35px;
    cursor: pointer;
    
    text-align: center;
    margin: 0;
    text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.wait-background {

  background: linear-gradient(90deg, #ffff 0%, #ea46f2 50%, #ffff 55%, #ffff 100%);
  border-radius: 50%;
  background-size: 200% 200%;
  background-position: center;
  animation: bounce 1s both infinite;
  color:#50555e76
}

@keyframes bounce {
  0% {
    background-position: 200%;
  }
  /* 50% {
    background-position: bottom right;
    
  } */
  100% {
    background-position: 0%;
  }
}