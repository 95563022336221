input.editableText{
    font-weight: 500;
    padding: 2px;
    background-color: white;
    white-space: pre-line;
}

span.editableText{
 padding: 3px;
}

span.editableText:hover{
    padding: 2px;
    border: 1px dashed black;
}