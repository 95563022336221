span.prompt-parameter{
    font-weight: 900;
    background-color: cyan;
    padding: 1px;
    border: 1px dashed gray;
    line-height: 150%;
}

span.prompt-parameter.missing{
    font-weight: 900;
    background-color: rgb(255, 107, 127);
    padding: 1px;
    border: 1px dashed gray;
    line-height: 150%;
}

/* span.prompt-generated{
    font-weight: 900;
    background-color: rgb(111,255,175);
} */

span.prompt-generated mark{
    background-color: rgb(111,255,175);
    display: inline;
    white-space: pre-line;
    padding: 1px;
    border: 1px dashed gray;
    line-height: 150%;
    
    
}

span.prompt{
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
    font-weight: 600;
    color: dimgrey;
}