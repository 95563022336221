.searchInput::-webkit-search-decoration,
.searchInput::-webkit-search-cancel-button,
.searchInput::-webkit-search-results-button,
.searchInput::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.searchInput{
    border: 0px;
    border-radius: 8px;
    font: inherit;
    margin: 1px;
    min-height: 1em;
    max-height: 10em;
    min-width: 100;
    max-width: 100%;
    resize: vertical;
    
    
}

.searchInput:focus {
  outline: none;
}