.editor{
    white-space:"pre-wrap"  !important;
    font-size: 10pt;
    border: 0.2px rgb(114, 114, 114) solid;
    background: white;
    padding: 5px;
    margin: 10px 10px 0px 10px;
    
    border-radius: 5px;
}


.generated-box{
    white-space:"pre-wrap"  !important;
    font-size: 10pt;
    border: 0.2px rgb(114, 114, 114) solid;
    background: white;
    padding: 5px;
    margin: 2px 10px;
    
    border-radius: 5px;
    position: sticky;
    bottom: 0px;
}

.editField{
    padding: 4px !important;
    background-color: white;
    font-size: 12px !important;
    margin-bottom: 5px;
    font-weight: 400 !important;
}
