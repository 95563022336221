
.prompt-template-box{
    background-color: rgba(255,255,255,0.9);
    border: 1px solid gray;
    margin: 10px;
    padding:5px 10px 5px 5px;
    border-radius: 10px 10px 10px 0px;
}

.prompt-template-box.version{
    margin: 5px 25px;
}

.prompt-template-box-text{
    text-align: start;
    font-family: monospace;
}

.prompt-template-version-badge{
    background-color: #b269b631;
    border: 0.5px solid #cb17d6;
    color: #4a014e;
    border-radius: 5px;
    padding: 0px 5px;
    height: 18px;
}

.version-template-name{
    background-color: #b269b631;
    border: 0.5px solid #cb17d6;
    color: #4a014e;
    border-radius: 5px;
    padding: 2px 5px;
    
}

.prompt-template-version-badge .versionNumber{
    font-size: 18px;
    vertical-align: top;
    
    
}