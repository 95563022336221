
.minibadge-value{
    vertical-align: sub;
    font-size: 12px;
    line-height: 120%;
    margin: -2px 2px 2px;
    font-weight: 600;
    color: white;
    background-color: #cb17d6;
    padding: 1px 5px;
    border-radius: 10px;
}