
.prompt-test-box{
    background-color: rgba(255,255,255,0.9);
    border: 1px solid gray;
    margin: 10px;
    padding:5px 10px 10px 5px;
    border-radius: 10px;
    
}

.run-details-header,  .unit-test-header {
    background: white;
    position: sticky;
    top: 0;
    margin-bottom: 10px;
    padding: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 10;
}

.unit-test-header{
    /* margin-bottom: -15px  !important; */

}



.prompt-test-box.run{
    margin: 5px 25px;
}

.prompt-test-box.selected{
    
    border: #6FFFB0 3px solid;
    
}

.prompt-test-run-badge{
    background-color: #b269b631;
    border: 0.5pxs solid #cb17d6;
    color: #4a014e;
    border-radius: 5px;
    padding: 2px 5px;
    
    
}

.version-template-name{
    background-color: #b269b631;
    border: 0.5px solid #cb17d6;
    color: #4a014e;
    border-radius: 5px;
    padding: 2px 5px;
    
}

.prompt-template-version-badge .versionNumber{
    font-size: 18px;
    vertical-align: top;
    
    
}