.chartWidget{
 
 padding: 0px;
 
}

.cardWidget div{
    /* border: 1px solid blue;  */

}
.chartBoundary div{
    overflow: hidden;
    border-radius: 8px;
    /* border: 1px solid red;  */
    z-index: 1;
}

.cardWidget{
    margin: 10px;
    font-weight: 900;
    border-radius: 10px;
    background-color: rgba(255,255,255,1);
    padding: 10px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.widgetBox{
    margin: 10px;
    height: 100%;
    font-weight: 900;
    border-radius: 10px;
    background-color: rgba(255,255,255,1);
    padding: 10px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cardWidget span{
    z-index: 5;
}