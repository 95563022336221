.button span{
    transition: transform 0.5s ease;
}

.button.primary {
    transform: unset !important;
    background: #cb17d6;
    font-weight: 700;
    color: white;
    border: 2px solid #cb17d6;
    
}

.button.secondary {
    border: 2px solid #cb17d6;
    
    font-weight: 700;
    
    
}

.button.primary:hover{
    transform: unset !important;
    background-color: #6FFFB0;
    border: 2px solid #6FFFB0;
    color: black;
}
.button.secondary:hover{
    transform: unset !important;
    border: 2px solid #6FFFB0;
    
}
.button.default-border {
    border: 1px solid #90949b;
}

.button {
    border: 1px solid transparent;
}

.button:hover {
    border: 1px solid;
}

.disabled-button{
    opacity: 0.5;
    cursor: default;
}