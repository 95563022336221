

.sessionsPanel{
    box-shadow: 10px 0 0 rgba(0, 0, 0, 0.5);
    /* //background-color: rgb(250, 250, 250); */
    background-color: #90949b !important;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px ;
    /* , rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.sessionBox{
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    margin: 5px;
    
}